<template>
  <b-modal
      :id="'modal-delete-custom-font-' + tableIndex"
      :ref="'modal-delete-custom-font-' + tableIndex"
      footer-class="flex-nowrap"
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.deleteCustomFont.title') }}</h3>
    </template>

    <template #default>
      <div class="delete-icon text-center">
        <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
        <div class="mt-2 mb-4">{{ $t('modals.deleteCustomFont.message') }}</div>
      </div>
    </template>

    <template #modal-footer="{ close }">
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          variant="primary"
          class="p-3"
          block
          @click="deleteCustomFont"
      >
        {{ $t('modals.deleteCustomFont.validate') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalDeleteCustomFont',
  props: ['customFont', 'tableIndex'],
  methods: {
    deleteCustomFont() {
      this.$emit('deleteCustomFont', this.tableIndex);
      const modalRef = `modal-delete-custom-font-${this.tableIndex}`;
      this.$refs[modalRef].hide();
    },
  },
};
</script>

<style scoped>

</style>
